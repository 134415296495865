import React, { useEffect } from "react";
import { H1, Text, FlexLayout } from "@deity/falcon-ui";
import TagManager from "react-gtm-module";
import Helmet from "react-helmet";
import { useCookies } from "react-cookie";
import { Shipment } from "benlux-ecommerce-ui";

import { LastOrderQuery } from "../../queries/LastOrderQuery";

const CheckoutConfirmationWrapper = () => {
  return (
    <LastOrderQuery>
      {({ lastOrder }) => <CheckoutConfirmation lastOrder={lastOrder} />}
    </LastOrderQuery>
  );
};

const CheckoutConfirmation = ({ lastOrder }) => {
  const [cookie, setCookie] = useCookies(["transactionsIds"]);

  useEffect(() => {
    const previousTransactionsIds = cookie.transactionsIds
      ? cookie.transactionsIds.split(",")
      : [];

    if (previousTransactionsIds.indexOf(lastOrder.incrementId) === -1) {
      const items = lastOrder.items.map(item => {
        return {
          id: item.productId,
          name: item.manufacturer + " - " + item.name,
          brand: item.manufacturer,
          variant: item.options
            ? item.options.map(option => option.value).join(" - ")
            : "",
          price: item.rowTotalInclTax || 0,
          quantity: item.qty
        };
      });

      const order = {
        id: lastOrder.incrementId,
        items,
        total: lastOrder.grandTotal,
        currency: lastOrder.orderCurrencyCode,
        shipping: lastOrder.shippingInclTax,
        tax: lastOrder.taxAmount
      };

      if (typeof window !== "undefined") {
        try {
          TagManager.dataLayer({
            dataLayer: {
              event: "Purchase",
              order: order
            }
          });

          previousTransactionsIds.push(lastOrder.incrementId);
          setCookie("transactionsIds", previousTransactionsIds.join(","), {
            path: "/"
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [lastOrder]);

  return (
    <>
      <Helmet>
        <title>Confirmation de commande</title>
        <meta name="page-loaded" content="true" />
      </Helmet>
      <FlexLayout my="xxl" flexDirection="column" alignItems="center">
        <H1 mb="xl">Merci pour votre commande !</H1>
        <Text>{`Nous avons bien reçu votre commande n°${lastOrder.incrementId} :`}</Text>
        <Shipment order={lastOrder} isLayout={true} />
      </FlexLayout>
    </>
  );
};

export default CheckoutConfirmationWrapper;
